import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import moment from 'moment'
import { black } from '../theme'
import Layout from '../components/Layout'
import { isWindowAvailable } from '../util'
import Stripe from '../components/Stripe'
import Listing from '../components/Listing'
import Router from '../components/Router'
import Footer from '../components/Footer'
import PageTitle from '../components/PageTitle'
import { first } from 'lodash'

class ReleaseListingTemplate extends React.Component {
  constructor (props) {
    super(props)
    const normalizedListing = props.data.allWordpressPage.edges.map((release) => {
      const image = release.node.acf.release_artwork
      const sizes = (image && image.localFile) ? image.localFile.childImageSharp.sizes : null

      return {
        title: release.node.title,
        id: release.node.acf.release_id,
        release_date: moment(release.node.acf.release_date, 'DD/MM/YYYY').format('X'),
        release_date_old: release.node.acf.release_date,
        attribution: {
          source: first(release.node.acf.release_artist).post_title,
          link: `/artists/${first(release.node.acf.release_artist).post_name}`
        },
        sizes: sizes,
        link: release.node.link
      }
    })
      .sort((a, b) => {
        return a.release_date - b.release_date
      })
      .reverse()
    this.state = {
      releases: normalizedListing
    }
  }

  render () {
    const siteMetadata = this.props.data.site.siteMetadata
    const currentPage = this.props.data.wordpressPage
    const bodyStyle = (isWindowAvailable) ? { style: `background-color: ${black.mid};` } : null
    return (
      <Layout>
        { currentPage && currentPage.acf.seo_page_title &&
          <Helmet title={currentPage.acf.seo_page_title} bodyAttributes={bodyStyle} />
        }
        { currentPage && !currentPage.acf.seo_page_title &&
          <Helmet title={`${siteMetadata.title} - ${currentPage.title}`} bodyAttributes={bodyStyle} />
        }
        <Router
          items={first(this.props.data.allWordpressWpApiMenusMenusItems.edges).node.items}
        />
        <PageTitle title={currentPage.title} prefix />
        <Listing items={this.state.releases} link />
        <Stripe top />
        <Stripe filter />
        <Footer />
      </Layout>
    )
  }
}

export default ReleaseListingTemplate

export const ReleaseListingQuery = graphql`
  query releaseListingPageQuery($id: String!) {
    allWordpressWpApiMenusMenusItems(
      filter: { name: { regex: "/Router/" } },
      sort: { fields: [name], order: DESC}
    ) {
      edges {
        node {
          id
          name
          items {
            title
            url
            type
          }
        }
      }
    }

    allWordpressPage(filter: {categories: {elemMatch: {name: {eq: "Release"}}}}, sort: {fields: [acf___release_date], order: ASC}) {
      edges {
        node {
          categories {
            name
          }
          title
          link
          acf {
            release_artist {
              post_title
              post_name
            }
            release_id
            release_date
            release_buy_link
            release_artwork {
              localFile {
                childImageSharp {
                  sizes(
                    maxWidth: 800,
                    maxHeight: 800
                  ) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        seo_page_title
      }
    }
    
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
